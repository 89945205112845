import Pill from '@/shared/components/flip-ui-kit/Pill';
import { CheckCircleIcon, CloseCircleIcon, RefundIcon } from '@/shared/icons/small';
import Lottie from '@/shared/utils/Lottie';
import inProgressJson from '@/shared/utils/lotties/in-progress.json';
import {
  type SwapStatus,
  isFailedStatus,
  isSuccessStatus,
  isRefundedStatus,
  isPartiallyRefundedStatus,
  isPartiallyRefundingStatus,
} from '../helpers/swap';

export const PartialRefundPill = () => (
  <Pill
    color="neutral"
    iconColor="text-cf-blue-2"
    text="Partially Refunded"
    Icon={RefundIcon}
    maxWidth={false}
  />
);

export const RefundSuccessPill = () => (
  <Pill color="neutral" iconColor="text-cf-blue-2" text="Refunded" Icon={RefundIcon} />
);

export const SwapSuccessPill = () => (
  <Pill color="neutral" iconColor="text-cf-green-1" text="Success" Icon={CheckCircleIcon} />
);

export const SwapFailedPill = () => (
  <Pill color="neutral" iconColor="text-cf-red-1" text="Failed" Icon={CloseCircleIcon} />
);

const InProgressLottie = () => (
  <Lottie as="span" autoplay speed={1} loop animationData={inProgressJson} />
);

export const SwapInProgressPill = () => (
  <Pill color="neutral" text="In progress" Icon={InProgressLottie} />
);

export const SwapStatusPill = ({ status }: { status: SwapStatus }) => {
  if (isFailedStatus(status)) {
    return <SwapFailedPill />;
  }
  if (isPartiallyRefundedStatus(status)) {
    return <PartialRefundPill />;
  }
  if (isPartiallyRefundingStatus(status)) {
    return <SwapInProgressPill />;
  }
  if (isRefundedStatus(status)) {
    return <RefundSuccessPill />;
  }
  if (isSuccessStatus(status)) {
    return <SwapSuccessPill />;
  }
  return <SwapInProgressPill />;
};
