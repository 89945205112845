import { type ChainflipChain } from '@chainflip/utils/chainflip';
import { abbreviate } from '@chainflip/utils/string';
import classNames from 'classnames';
import { Tooltip, Link, CopyButton } from '@/shared/components';
import { SendIcon, ShieldTickIcon } from '@/shared/icons/small';
import { buildExplorerLink } from '@/shared/utils/chainflip';

type AddressesProps = {
  destinationAddress: string;
  destinationChain: ChainflipChain;
  setHighlightAddress: ((address: string) => void) | undefined;
  highlightAddress: string | undefined;
  refundAddress: string | null | undefined;
  sourceChain: ChainflipChain;
};

export const Addresses = ({
  destinationAddress,
  destinationChain,
  setHighlightAddress,
  highlightAddress,
  refundAddress,
  sourceChain,
}: AddressesProps) => (
  <div className="flex flex-col gap-y-1 whitespace-nowrap font-aeonikMono">
    <div className="flex flex-row items-center space-x-1">
      <SendIcon />
      <Tooltip content={destinationAddress}>
        <Link
          noPropagate
          href={buildExplorerLink(destinationChain, 'address', destinationAddress)}
          underline
          onMouseOver={() => setHighlightAddress?.(destinationAddress)}
          onMouseOut={() => setHighlightAddress?.('')}
          className={classNames(
            highlightAddress === destinationAddress
              ? 'border-cf-green-1 bg-cf-green-4'
              : 'border-transparent',
            'rounded-md border border-dashed p-1',
          )}
        >
          {abbreviate(destinationAddress)}
        </Link>
      </Tooltip>
      <CopyButton textToCopy={destinationAddress} />
    </div>
    {refundAddress && (
      <div className="flex flex-row items-center space-x-1">
        <ShieldTickIcon />
        <Tooltip content={refundAddress}>
          <Link
            noPropagate
            href={buildExplorerLink(sourceChain, 'address', refundAddress)}
            underline
            onMouseOver={() => setHighlightAddress?.(refundAddress)}
            onMouseOut={() => setHighlightAddress?.('')}
            className={classNames(
              highlightAddress === refundAddress
                ? 'border-cf-green-1 bg-cf-green-4'
                : 'border-transparent',
              'rounded-md border border-dashed p-1',
            )}
          >
            {abbreviate(refundAddress)}
          </Link>
        </Tooltip>
        <CopyButton textToCopy={refundAddress} />
      </div>
    )}
  </div>
);
