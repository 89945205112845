import { useRouter } from 'next/router';

type Props = React.ComponentProps<'tr'> & {
  href: string;
  forceNewTab?: boolean;
};

const TableRow: React.FC<Props> = ({ children, forceNewTab, href, ...props }) => {
  const router = useRouter();

  return (
    <tr
      {...props}
      onClick={(e) => {
        if (e.ctrlKey || e.metaKey || forceNewTab) {
          window.open(href, '_blank');
        } else {
          router.push(href);
        }
      }}
      onAuxClick={(e) => {
        if (e.button === 1) {
          window.open(href, '_blank');
        }
      }}
    >
      {children}
    </tr>
  );
};

export default TableRow;
